/* eslint-disable no-empty */
<template>
  <div class="select">
    <van-overlay :show="showloading" @click="showloading = false">
      <div class="wrapper" @click.stop>
        <van-loading
          v-if="showloading == true"
          class="loading"
          color="#1989fa"
          vertical
          >加载中...</van-loading
        >
      </div>
    </van-overlay>
    <van-dialog v-model="showModel1" title="提示" :before-close="beforeClose" confirmButtonColor="#3a68f2">
      日报生成中，成功后会给您推送该日报。如超过5分钟未收到日报推送，请重新生成日报或联系管理员
    </van-dialog>
    <van-dialog v-model="showModel2" title="提示" confirmButtonColor="#3a68f2">
      {{ showModel3 }}
    </van-dialog>
    <van-dialog v-model="showTip" title="" :before-close="beforeClose1"  confirmButtonColor="#3a68f2">
      {{ tip1 }}
    </van-dialog>
     <van-dialog v-model="showTip1" title="" :before-close="beforeClose2"  confirmButtonColor="#3a68f2">
      {{ tip2 }}
    </van-dialog>
    <div class="part">
      <div class="part1">
        发起人：<span>{{ name }}</span>
      </div>
      <div class="part2">
        <div class="label1">日报日期</div>
        <div class="ribao_top_xuashi" @click="shijian">
          <span>{{ currentDate_top }}</span>
          <van-icon name="notes-o" size="20" />
        </div>
        <div class="label1">下一个工作日</div>
        <div class="ribao_top_xuashi" @click="shijian1">
          <span>{{ currentDate_top1 }}</span>
          <van-icon name="notes-o" size="20" />
        </div>

        <div class="label1" v-show="comOfficeType==1">办公类型</div>
        <div class="" style="font-size: 12px;" v-show="comOfficeType==1">
          <span>
            <van-radio-group v-model="officeType" class="officeType" direction="horizontal" @change="changeType">
               <van-radio name="1">远程办公</van-radio>
               <van-radio name="0">打卡办公</van-radio>
            </van-radio-group>
          </span>
        </div>
        <div class="label1" v-show="officeType==0">办公时长</div>
        <div class="" style="font-size: 12px;" v-show="officeType==0">
          <span>
            <van-radio-group v-model="dateType" class="officeType" direction="horizontal" @change="changeDateType">
               <van-radio name="0">全天</van-radio>
               <van-radio name="1">半天</van-radio>
            </van-radio-group>
          </span>
        </div>

      </div>
    </div>
    <div class="part3">
      特别注意：<br />
      1.系统每天18:00会全局同步飞书的所有任务，同步进程会需要几分钟的时间。<br />
      2.当您点生日报的时候，系统会检查您所有的任务信息是否是最新。如果不是，则系统会再次去同步任务信息，同步结束后会发信息提醒您再次生日报。<br />
      3.默认日报提取时间为选择日期的上午8点至次日的上午8点。<br />
      4.如需补交日报，上午08:00前可直接补交，上午08:00后需完成当日所有任务后才能补交。
    </div>
    <div class="btn1"  v-show="showBtn" @click="getTasks">同步任务</div>
    <div class="btn1"  v-show="showBtn" @click="submit">下一步</div>
    <van-popup
      v-model="showshi"
      @confirm="queshi"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="queshi"
        @cancel="shixiao"
      />
    </van-popup>
    <van-popup v-model="showshi1" position="bottom" :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="currentDate1"
        type="date"
        title="选择年月日"
        @confirm="queshi1"
        @cancel="shixiao1"
      />
    </van-popup>
  </div>
</template>

<script>
import topa from "./../components/HelloWorld.vue";
import { submitLog,LogInsert1, LogInsert,getLogByLogDate, log_getNextDay,getToken,getToken1,asana_login,asana_login1,getCompanyInfo,getInfo,getPersonTask } from "./../utils/api";
import { Dialog, Toast } from "vant";

export default {

  data() {
    return {
      currentDate_top: new Date(),
      currentDate_top1: new Date(),
      showshi1: false,
      showTip:false,
      showTip1:false,
      tip1:"",
      tip2:"",
      showloading:false,
      showshi: false,
      showBtn:true,
      asanaCode:"",
      currentDate: new Date(),
      currentDate1: new Date(),
      currentDate2: null,
      showModel1: false,
      showModel3: "",
      companyId:"",
      showModel2: false,
      name: "",
      comOfficeType:"1", //公司办公类型
      officeType:"0",
      dateType:"0",
    };
  },
  created(){
    if(localStorage.getItem('name')){
      this.name = localStorage.getItem('name')
    }

    //获取用户公司信息开始
    if(localStorage.getItem('officeType')){
      this.comOfficeType = localStorage.getItem('officeType')
    }

    //获取用户公司信息结束

    // localStorage.removeItem('token')
    localStorage.removeItem('isH5')
    localStorage.removeItem('logDetails')
    localStorage.removeItem('TomorrowFeedback')
    let day3 = null;
    // this.currentDate_top = this.timeFunc(new Date().getTime(), "date");
    let time1 = new Date().getTime()
    let time2 = new Date((this.timeFunc(new Date().getTime(), "date") +" 08:00").replace(/-/g, '/')).getTime()
    if(time1>=time2){
      this.currentDate_top = this.timeFunc(new Date().getTime(), "date");
      this.currentDate2 =  this.timeFunc(new Date().getTime(), "date");
    }else{
      this.currentDate_top = this.timeFunc((new Date().getTime()-24 * 60 * 60 * 1000), "date");
      this.currentDate2 =  this.timeFunc((new Date().getTime()-24 * 60 * 60 * 1000), "date");
    }
    log_getNextDay(this.currentDate_top).then(res=>{
        if(res.code==200){
          this.currentDate_top1 = res.data.nextDate
        }else{
          Dialog.alert({
            message: "系统繁忙，请稍后再试",
            confirmButtonColor:"#3a68f2"
          }).then(() => {
          });
        }
    })

    // if (new Date().getDay() == 5) {
    //   day3 = new Date();
    //   day3.setTime(day3.getTime() + 3 * 24 * 60 * 60 * 1000);
    //   this.currentDate_top1 =
    //     day3.getFullYear() +
    //     "-" +
    //     (day3.getMonth() + 1 < 10
    //       ? "0" + (day3.getMonth() + 1)
    //       : day3.getMonth() + 1) +
    //     "-" +
    //     (day3.getDate()< 10  ? "0" + day3.getDate() 
    //       : day3.getDate());
    // } else if (new Date().getDay() == 6) {
    //   day3 = new Date();
    //   day3.setTime(day3.getTime() + 2 * 24 * 60 * 60 * 1000);
    //   this.currentDate_top1 =
    //     day3.getFullYear() +
    //     "-" +
    //     (day3.getMonth() + 1 < 10
    //       ? "0" + (day3.getMonth() + 1)
    //       : day3.getMonth() + 1) +
    //     "-" +
    //      (day3.getDate()< 10  ? "0" + day3.getDate() 
    //       : day3.getDate());
    // } else {
    //   day3 = new Date();
    //   day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
    //   this.currentDate_top1 =
    //     day3.getFullYear() +
    //     "-" +
    //     (day3.getMonth() + 1 < 10
    //       ? "0" + (day3.getMonth() + 1)
    //       : day3.getMonth() + 1) +
    //     "-" +
    //     (day3.getDate()< 10  ? "0" + day3.getDate() 
    //       : day3.getDate());
    // }
  },
  methods: {
    isQyweixin(){
	//判断当前入口是PC端还是APP端
	      let flag = navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )==null?false:true;

        return flag?this.is_weixin()?false:false:this.is_weixin()?true:false;
    },
    is_weixin(){
      //判断是在企业微信打开 还是 在浏览器打开
        return navigator.userAgent.toLowerCase().indexOf('feishu') !== -1
    },
    async getLoginAsana(code,code1){
      let that = this;
      this.showTip = false;
      this.showTip1 = false;
      console.log('32233232')
      if(this.isQyweixin()){
        getToken1({"code":code}).then((res) => {
          console.log(res,'PC')
          if (res.code == 200) {
            localStorage.setItem("token", res.data.token);
            asana_login(code1).then((res1) => {
               console.log(res1,'PC res1')
              if (res1.code == 200) {
                this.tip1 = "授权成功"
                this.showTip = true;
              }else{
                this.tip2 = res1.msg
                this.showTip1 = true;
              }
            });
          }else{
             Dialog.alert({
                  message: res1.msg,
                  confirmButtonColor:"#3a68f2"
              }).then(() => {
              });
            // this.$router.push({
            //   path:"empty1",
            //   query:{
            //     content: encodeURIComponent(res1.msg)
            //   }
            // })
          }
        });
      }else{
        getToken(code).then((res) => {
          console.log(res,'Mobile')
          if (res.code == 200) {
            localStorage.setItem("token", res.data.token);
            asana_login1(code1).then((res1) => {
              if (res1.code == 200) {
                  Dialog.alert({
                  message: '授权成功',
                  }).then(() => {
                  });
              }else{
                Dialog.alert({
                    message: res1.msg,
                    confirmButtonColor:"#3a68f2"
                }).then(() => {
                });
                // this.$router.push({
                //   path:"empty1",
                //   query:{
                //     content: encodeURIComponent(res1.msg)
                //   }
                // })
              }
            });
          }else{
             Dialog.alert({
                  message: res1.msg,
                  confirmButtonColor:"#3a68f2"
              }).then(() => {
              });
            // this.$router.push({
            //   path:"empty1",
            //   query:{
            //     content: encodeURIComponent(res1.msg)
            //   }
            // })
          }
        });
      }
    },

    getTasks(){
      let that = this
      Toast.clear();
      Toast.loading('任务同步中...');
      getPersonTask().then(res=>{        
        if(res.code == 200){
          that.tasks = res.data
          Toast.clear();
          Toast.success('同步任务成功！');
        }else{
          Dialog.alert({
            message: "系统繁忙，请稍后再试",
            confirmButtonColor:"#3a68f2"
          }).then(() => {
          });
        }
      })
    },
    getLog(){
      let that = this
      getLogByLogDate(that.currentDate_top).then(res=>{
        if(res.code == 200){
          that.log = res.data
          Dialog.alert({
            message: "获取日志成功",
            confirmButtonColor:"#3a68f2"
          }).then(() => {
          });
        }else{
          Dialog.alert({
            message: "系统繁忙，请稍后再试",
            confirmButtonColor:"#3a68f2"
          }).then(() => {
          });
        }
      })},
    canSubmit(){
      let that = this
      LogInsert1({
        logDate:  that.currentDate_top,
        nextDate: that.currentDate_top1,
      }).then(res=>{
        that.showloading = false
        if(res.code == 200){
           that.disabled = false
        }else{
            Dialog.alert({
              message: "系统繁忙，请稍后再试",
              confirmButtonColor:"#3a68f2"
            }).then(() => {
              that.disabled = true
            });
        }
      })
    },
    async login(code) {
      console.log('3333')
      let that = this;
      getToken(code).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("token", res.token);
          console.log('555：')
          if(res.user.companyId){
            //获取用户公司信息开始
            console.log('555',res.user.companyId)
            getCompanyInfo(res.user.companyId).then(res1=>{
                      console.log("获取officeType:::",res1.data)
                      this.comOfficeType=res1.data.officeType;
                })
            //获取用户公司信息结束

            if(!res.user.asanaName){
              localStorage.setItem("isH5","1")
            //  location.href = 'https://app.asana.com/-/oauth_authorize?client_id=1201450570166924&redirect_uri=https://pmsh5.ilohhas.com&response_type=code'
            this.$router.replace({
                path:"empty4",
                query:{
                  currentDate_top:this.currentDate_top,
                  currentDate_top1:this.currentDate_top1
                }
              })  
          }else{
              this.$router.replace({
                path:"empty4",
                query:{
                  currentDate_top:this.currentDate_top,
                  currentDate_top1:this.currentDate_top1
                }
              })
            }
          }else{
            //  Dialog.alert({
            //       message: "该企业未开通，请联系管理员开通",
            //       confirmButtonColor:"#3a68f2"
            //   }).then(() => {
            //   });
            this.$router.push({
              path:"empty1",
              query:{
                content: encodeURIComponent("该企业未开通，请联系管理员开通")
              }
            })
          }
        }else{
          if(res.msg=="获取用户信息失败！"){

          }else{
             Dialog.alert({
                  message: "系统繁忙，请稍后再试",
                  confirmButtonColor:"#3a68f2"
              }).then(() => {
                //  location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf54d14636e137fd2&redirect_uri=https%3A%2F%2Fpmsh5.ilohhas.com%2FselectDate&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
              });
          }
          
            // this.$router.push({
            //   path:"empty1",
            //   query:{
            //     content: encodeURIComponent(res.msg)
            //   }
            // })
        }
      });
    },

    changeTime() {
      log_getNextDay(this.currentDate_top).then(res=>{
          if(res.code==200){
            this.currentDate_top1 = res.data.nextDate
          }else{
            Dialog.alert({
              message: "系统繁忙，请稍后再试",
              confirmButtonColor:"#3a68f2"
            }).then(() => {
            });
          }
      })
      // let day3 = null;
      // if (new Date(this.currentDate_top).getDay() == 5) {
      //   day3 = new Date(this.currentDate_top);
      //   day3.setTime(day3.getTime() + 3 * 24 * 60 * 60 * 1000);
      //   this.currentDate_top1 =
      //     day3.getFullYear() +
      //     "-" +
      //     (day3.getMonth() + 1 < 10
      //       ? "0" + (day3.getMonth() + 1)
      //       : day3.getMonth() + 1) +
      //     "-" +
      //     (day3.getDate()< 10  ? "0" + day3.getDate() 
      //     : day3.getDate());
      // } else if (new Date(this.currentDate_top).getDay() == 6) {
      //   day3 = new Date(this.currentDate_top);
      //   day3.setTime(day3.getTime() + 2 * 24 * 60 * 60 * 1000);
      //   this.currentDate_top1 =
      //     day3.getFullYear() +
      //     "-" +
      //     (day3.getMonth() + 1 < 10
      //       ? "0" + (day3.getMonth() + 1)
      //       : day3.getMonth() + 1) +
      //     "-" +
      //      (day3.getDate()< 10  ? "0" + day3.getDate() 
      //     : day3.getDate());
      // } else {
      //   day3 = new Date(this.currentDate_top);
      //   day3.setTime(day3.getTime() + 24 * 60 * 60 * 1000);
      //   this.currentDate_top1 =
      //     day3.getFullYear() +
      //     "-" +
      //     (day3.getMonth() + 1 < 10
      //       ? "0" + (day3.getMonth() + 1)
      //       : day3.getMonth() + 1) +
      //     "-" +
      //     (day3.getDate()< 10  ? "0" + day3.getDate() 
      //     : day3.getDate());
      // }
    },
    shijian() {
      this.showshi = true;
      this.currentDate_top = this.timeFunc(
        new Date(this.currentDate_top).getTime(),
        "date"
      );
      this.currentDate = new Date(this.currentDate_top);
    },
    getlog_getLog2(logDate,nextDate){
      LogInsert1({
        logDate: logDate,
        nextDate: nextDate,
      }).then(res=>{
           if (res.code == 200) {
            this.getlog_getLog1(this.logDate,this.nextDate);
           }else{
              Dialog.alert({
                  message: "系统繁忙，请稍后再试",
                  confirmButtonColor:"#3a68f2"
              }).then(() => {
              });
           }
        })
    },
    async submit() {
      let that = this;
      if(new Date(that.currentDate_top1)<=new Date(that.currentDate_top)){
          Dialog.alert({
              message: "下一工作日不得小于日报日期",
              confirmButtonColor:"#3a68f2"
          }).then(() => {
          });
          return
      }
     let time1 =  new Date((this.timeFunc(new Date(that.currentDate_top.replace(/-/g, '/')).getTime()+ 24*60*60*1000, "date")+" 8:00").replace(/-/g, '/')).getTime()
     if(new Date().getTime()>time1){
        getLogByLogDate(this.currentDate_top).then(res=>{
          if(res.data){
            Dialog.alert({
              title: '',
              confirmButtonColor:"#3a68f2",
              message: '已生过日报',
            })
          }else{
            // that.showloading = true;
            // LogInsert1({
            //   logDate:  that.currentDate_top,
            //   nextDate: that.currentDate_top1,
            // }).then(res=>{
            //   that.showloading = false
            //   if(res.code == 200){
            that.$router.push({
              name: "Ribao",
              query: { logDate: that.currentDate_top,nextDate: that.currentDate_top1,officeType: this.officeType,dateType:this.dateType},
            });
            //   }else{
            //       Dialog.alert({
            //         message: res.msg,
            //         confirmButtonColor:"#3a68f2"
            //       }).then(() => {
            //       });
            //   }
            // })
          }
        })
      }else{
        // that.showloading = true;
        // LogInsert1({
        //   logDate:  that.currentDate_top,
        //   nextDate: that.currentDate_top1,
        // }).then(res=>{
        //   that.showloading = false
        // if(res.code == 200){
        that.$router.push({
          name: "Ribao",
          query: { logDate: that.currentDate_top,nextDate: that.currentDate_top1,officeType: this.officeType,dateType:this.dateType },
        });
        // }
        //   }else{
        //       Dialog.alert({
        //         message: res.msg,
        //         confirmButtonColor:"#3a68f2"
        //       }).then(() => {
        //       });
        //   }
        // })
      }
      // this.showloading = true;
      // LogInsert1({
      //   logDate:  this.currentDate_top,
      //   nextDate: this.currentDate_top1,
      // }).then(res=>{
      //   this.showloading = false
      //   if(res.code == 200){
      //       this.$router.push({
      //         name: "Ribao",
      //         query: { logDate: this.currentDate_top,nextDate: this.currentDate_top1 },
      //       });
      //   }else{
      //       Dialog.alert({
      //         message: res.msg,
      //         confirmButtonColor:"#3a68f2"
      //       }).then(() => {
      //       });
      //   }
      // })
      //  this.$router.push({
      //     name: "Ribao",
      //     query: { logDate: this.currentDate_top,nextDate: this.currentDate_top1 },
      //   });
      //   return
      // 获取动态推送suite_ticket  返回的是xml 需要处理
      // LogInsert({
      //   logDate: that.currentDate_top,
      //   nextDate: that.currentDate_top1,
      // }).then((res) => {
      //   if (res.code == 200) {
      //     this.showModel1 = true;
      //     this.companyId =  res.data.companyId 
      //   } else {
      //     this.showModel1 = false;
      //     this.showModel2 = true;
      //     this.showModel3 = res.msg;
      //   }
      // });
    },
    // 点击时间取消按钮时
    shixiao() {
      this.currentDate_top = this.timeFunc(new Date().getTime(), "date");
      this.showshi = false;
    },
    // 点击时间取消按钮时
    shixiao1() {
      this.currentDate_top1 = this.timeFunc(new Date().getTime(), "date");
      this.showshi1 = false;
    },
    shijian1() {
      this.showshi1 = true;
      this.currentDate_top1 = this.timeFunc(
        new Date(this.currentDate_top1).getTime(),
        "date"
      );
      
      this.currentDate1 = new Date(this.currentDate_top1);
    },
    beforeClose : function (action, done) { // 点击事件 - 备注按钮提示框
        if (action === 'confirm') { // 确认
          this.showModel1 = false;
          this.$router.push({
            name: "Ribao",
            query: { logDate: this.currentDate_top,nextDate: this.currentDate_top1 ,officeType: this.officeType,dateType:this.dateType},
          });
          done(); // 关闭提示框
        } else if (action === 'cancel') { // 取消
          done(); // 关闭提示框
        }
    },
    beforeClose1 : function (action, done) { // 点击事件 - 备注按钮提示框
        if (action === 'confirm') { // 确认
          this.showTip = false;
          location.href = 'https://feishuh5.ilohhas.com/rediect'
          done(); // 关闭提示框
        } else if (action === 'cancel') { // 取消
          done(); // 关闭提示框
        }
    },
    beforeClose2 : function (action, done) { // 点击事件 - 备注按钮提示框
        if (action === 'confirm') { // 确认
          this.showTip1 = false;
          location.href = 'https://feishuh5.ilohhas.com/rediect'
          done(); // 关闭提示框
        } else if (action === 'cancel') { // 取消
          done(); // 关闭提示框
        }
    },
    // 当点击时间确认按钮时的事件
    queshi(e) {
      console.log(new Date());
      let shijian = this.timeFunc(new Date(e).getTime(), "date");
      console.log(shijian);
      this.currentDate_top = shijian;
      this.showshi = false;
      this.changeTime();
    },
    // 当点击时间确认按钮时的事件
    queshi1(e) {
      console.log(new Date());
      let shijian = this.timeFunc(new Date(e).getTime(), "date");
      console.log(shijian);
      this.currentDate_top1 = shijian;
      this.showshi1 = false;
    },
    // 时间处理函数
    timeFunc(content, type) {
      const date = new Date(content);
      const y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      const minute = date.getMinutes();
      const second = date.getSeconds();
      if (type === "date") {
        return y + "-" + m + "-" + d;
      }
      if (type === "time") {
        return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
      }
    },
  },
  components: { topa,  [Dialog.Component.name]: Dialog.Component, },
};
</script>

<style scope lang="less">
.select {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .van-overlay{
    background-color: transparent;
  }
  .loading {
    position: fixed;
    top: 40%;
    left: 45%;
  }
  /deep/.van-dialog__content {
      padding: 20px;
  }
  background-color: #fff;
  .part {
    width: 90%;
    border-radius: 5px;
    margin: 5px auto;
    background-color: #ddd;
    box-shadow: 2px 2px 5px #888888;
    .part1 {
      padding: 10px 20px;
      font-size: 16px;
      text-align: left;
      font-weight: bold;
      span {
        float: right;
        color: #3a68f2;
        font-weight: normal;
      }
    }
    .part2 {
      padding: 20px;
      background-color: #fff;
      border-radius: 0 0 5px 5px;
      .ribao_top_xuashi {
        width: 100%;
        padding: 0 10px;
        height: 100%;
        margin: 10px 0;
        height: 30px;
        line-height: 30px;
        background-color: #eee;
        border-right: 1px solid rgba(224, 227, 234, 100);
        border-left: 1px solid rgba(224, 227, 234, 100);
        box-sizing: border-box;
        font-size: 14px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .label1 {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .part3 {
    background-color: #fff;
    border-radius: 5px;
    margin: 0 auto;
    font-size: 13px;
    color: #333;
    text-align: left;
    line-height: 1.8;
    padding: 40px 30px 10px;
  }
  .btn1 {
    background-color: #3a68f2;
    width: 40%;
    margin: 30px auto;
    font-size: 18px;
    color: #fff;
    height: 36px;
    line-height: 36px;
    border-radius: 5px;
  }
  .van-dialog__content {
    padding: 20px;
  }
}
</style>